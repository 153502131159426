import React from "react"
import css from "./HexagonProjects.module.css"
import LoungeGlass from "../Projects/LoungeGlass"
import laTorre from "../Projects/laTorre"
import Solar from "../Projects/Solar"
import Biofuels from "../Projects/Biofuels"
import Zacapa from "../Projects/Zacapa"
import PpysBBq from "../Projects/PpysBBq"
import Stoves from "../Projects/Stoves"
import Chair from "../Projects/Chair"
import BioSeed from "../Projects/BioSeed"
import Biodome from "../Projects/Biodome"
import Urtisan from "../Projects/Urtisan"
import MecanoCNC from "../Projects/MecanoCNC"
import Particle from "../Particles/index"
import MenuPopupState from "../Menu/index"
import Circle from "../Circle/index"
import latorre from "../../images/latorre.jpg"
import biodiesel from "../../images/biodiesel.jpg"
import biodiesel1 from "../../images/biodiesel1.jpg"
import zacapa from "../../images/zacapa.jpg"
import zacapa1 from "../../images/zacapa1.jpg"
import airportlounge from "../../images/airportlounge.jpg"
import airportlounge1 from "../../images/airportlounge1.jpg"
import bbq from "../../images/bbq.png"
import chair from "../../images/chair.png"
import solarproject from "../../images/solarproject.png"
import biodome from "../../images/biodome.jpg"
import mecano from "../../images/mecano.jpg"
import mecano1 from "../../images/mecano1.jpg"



function HexagonProject() {
  return (
    <div className={css.hexagon}>
      <div className={css.wrapper}>
        <div className={css.item1}>
          <h2> PORTFOLIO</h2>
        </div>
      </div>
      <br></br>
      <br></br>

      <section>
        <article>
          <figure>
            <h2>LoungeGlass</h2>
            <LoungeGlass />
          </figure>
          <img
            alt
            src={airportlounge1}
          />
        </article>
        <article>
          <figure>
            <h2>LaTorre</h2>
            <laTorre />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Solar</h2>
            <Solar />
          </figure>
          <img
            alt
            src={solarproject}
          />
        </article>
        <article>
          <figure>
            <h2>Biofuels</h2>
            <Biofuels />
          </figure>
          <img
            alt
            src={biodiesel}
          />
        </article>
        <article>
          <figure>
            <h2>Zacapa</h2>
            <Zacapa />
          </figure>
          <img
            alt
            src={zacapa}
          />
        </article>
        <article>
          <figure>
            <h2>PpysBBq</h2>
            <PpysBBq />
          </figure>
          <img
            alt
            src={bbq}
          />
        </article>
        <article>
          <figure>
            <h2>Stoves</h2>
            <Stoves />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Chair</h2>
            <Chair />
          </figure>
          <img
            alt
            src={chair}
          />
        </article>
        <article>
          <figure>
            <h2>BioSeed</h2>
            <BioSeed />
          </figure>
          <img
            alt
            src={biodiesel1}
          />
        </article>
        <article>
          <figure>
            <h2>Biodome</h2>
            <Biodome />
          </figure>
          <img
            alt
            src={biodome}
          />
        </article>
        <article>
          <figure>
            <h2>Urtisan</h2>
            <Urtisan />
          </figure>
          <img
            alt
            src={latorre}
          />
        </article>
        <article>
          <figure>
            <h2>Mecano</h2>
            <MecanoCNC />
          </figure>
          <img
            alt
            src={mecano1}
          />
        </article>
      </section>

      {/* <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg> */}

      <div className={css.wrapper}>
        <div className={css.item1}>
          {/* <h2>Personal</h2> */}
        </div>
      </div>
    </div>
  )
}

export default HexagonProject
