import React from "react"
import css from "./style.scss"
import environmental from "./environmental.png"

function Circle() {
  return (
    <div>
      {/* <p class="coords">N 49° 16' 35.173" / W 0° 42' 11.30"</p> */}
      <div class="ellipses-container">
        <h2 class="greeting1">Alex</h2>
        <h2 class="greeting2">Morán</h2>

        <img className={css.Applogo} src={environmental} alt="logo" />

        <div class="ellipses ellipses__outer--thin">
          <h2 class="greeting"></h2>

          <div class="ellipses ellipses__orbit"></div>
        </div>

        <div class="ellipses ellipses__outer--thick"></div>
      </div>
    </div>
  )
}

export default Circle
