import React from "react"
import { Link, onClick } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HexagonProject from "../components/HexagonProjects"
// import Quote from "../components/Quote/index"
// import SocialIcons from "../components/SocialIcons/index"
// import Map from "../components/Map/index"
import ScrollBar from "../components/ScrollBar/index"
import PersonalProjects from "../components/PersonalProjects/index"
import scss from "../components/layout.scss"
import Particle from "../components/Particles"
import Circle from "../components/Circle"
// import NodeMailer from "../components/NodeMailer"
import Particles from "../components/Particles"
import ManfacturePage from "../components/ManfacturePage"

// import MenuPopUpState from "../components/Menu/index"

function IndexPage() {
  return (
    <>
      <Layout>
        <ScrollBar />
        {/* <MenuPopUpState /> */}
        <Particle />
        <Circle />
        <SEO title="Home" />
        {/* If I decide to add social icons */}
        {/* <SocialIcons /> */}

        <div className={scss.sectionDownload} id="Manufacture"></div>

        <HexagonProject />
        <div className={scss.sectionDownload} id="CNC Maching"></div>
        {/* <PersonalProjects /> */}

        <div className={scss.sectionDownload} id="Biofuel & Genetic Seed Modification"></div>
        <ManfacturePage />
        <div className={scss.sectionDownload} id="Research"></div>
        {/* <NodeMailer /> */}
        {/* <TestForm /> */}
        <div className={scss.sectionDownload} id="Grants and Awards"></div>

        {/* If I decide to add an interesting/relevant API */}
        {/* <Quote /> */}
        {/* Commented out to save credit on map */}
        {/* 
        <h3 style={{ textalign: "center" }}>
          Where I'm Going and Where I've Been
        </h3> */}
        {/* <Map /> */}
        {/* <SocialIcons /> */}
      </Layout>
    </>
  )
}

export default IndexPage
